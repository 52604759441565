<template>
  <CRow>
    <CCol col>
      <CCard>
        <CCardHeader>
          <CRow class="justify-content-between px-3">
            <h5>Bookings</h5>
            <div class="d-flex">
              <CSelect
                style="width: 180px"
                class="mx-2 my-0"
                horizontal
                label="Type"
                :value.sync="search.type"
                :options="search.options"
                @change="searchChangeType"
              />
              <CInput
                style="width: 400px"
                class="px-0 my-0"
                :placeholder="`Search ${search.placeholder}`"
                :value.sync="search.value"
              >
                <template #append>
                  <CButton color="primary" @click="doApplyFilter"
                    >Search</CButton
                  >
                  <CButton
                    class="ml-2"
                    size="sm"
                    color="info"
                    variant="outline"
                    @click="isShowFilter = !isShowFilter"
                  >
                    <CIcon
                      :name="isShowFilter ? 'cil-x' : 'cil-arrow-bottom'"
                    />
                    Filter</CButton
                  >
                </template>
              </CInput>
            </div>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CCollapse :show="isShowFilter">
            <CCard body-wrapper>
              <CRow>
                <h5 class="ml-3 flex-grow-1">Filter</h5>
                <CButton
                  class="ml-auto m-2"
                  variant="outline"
                  color="info"
                  @click="doResetFilter"
                  >Reset</CButton
                >
                <CButton class="m-2" color="info" @click="onApplyFilter"
                  >Apply</CButton
                >
              </CRow>
              <CRow>
                <CCol>
                  <treeselect
                    v-model="filter.status"
                    :multiple="true"
                    :options="filterData.status"
                    placeholder="Select status"
                  >
                    <div class="d-grid gap-4 d-md-flex" slot="before-list">
                      <CButton
                        class="mx-2"
                        size="sm"
                        variant="outline"
                        color="info"
                        @click="selectAll"
                        >Select All</CButton
                      >
                      <CButton
                        size="sm"
                        variant="outline"
                        color="info"
                        @click="deselectAll"
                        >Deselect All</CButton
                      >
                    </div>
                  </treeselect>
                </CCol>
                <CCol>
                  <treeselect
                    v-model="filter.categories"
                    :multiple="true"
                    :options="filterData.categories"
                    placeholder="Select category"
                  />
                </CCol>
                <CCol>
                  <date-picker
                    v-model="filter.dateRangeSelected"
                    range
                    format="YYYY-MM-DD"
                    placeholder="Created date"
                  ></date-picker>
                </CCol>
              </CRow>
            </CCard>
          </CCollapse>
          <CDataTable
            hover
            striped
            sorter
            border
            :loading="loading"
            :itemsPerPageSelect="false"
            :items="bookings"
            :fields="fields"
            :items-per-page="pagination.limit"
            :pagination="false"
          >
            <td slot="id" slot-scope="{ item }">
              <CLink :href="'#/bookings/' + item.id">
                {{ item.id }}
              </CLink>
            </td>
            <td slot="booking_no" slot-scope="{ item }">
              <span v-if="item.booking_no.length > 0">
                <CLink :href="'#/bookings/' + item.id">
                  {{ item.booking_no ? item.booking_no : '-' }}
                </CLink>
              </span>
              <span v-else>-</span>
            </td>

            <td slot="address" slot-scope="{ item }">
              {{ `#${item.unit_number}, ${item.address}, ${item.postal_code}` }}
            </td>
            <td slot="status" slot-scope="{ item }">
              <CBadge :color="getBadge(item.status)">
                {{ item.status }}
              </CBadge>
            </td>
            <td slot="total_price" slot-scope="{ item }">
              {{ item.total_price | currency100 }}
            </td>
            <td slot="consumer_id" slot-scope="{ item }">
              <CLink :href="'#/consumers/' + item.consumer_id">
                {{ item.consumer_id }}
              </CLink>
            </td>
            <td slot="provider_id" slot-scope="{ item }">
              <span v-if="item.provider_id">
                <CLink :href="'#/sp/' + item.provider_id">
                  {{ item.provider_id }}
                </CLink>
              </span>
              <span v-else>-</span>
            </td>
            <td slot="category" slot-scope="{ item }">
              {{ listCategories[item.category_id] || '-' }}
            </td>

            <td slot="subCategory" slot-scope="{ item }">
              {{ listSubCategories[item.subcategory_id] || '-' }}
            </td>

            <td slot="appointment" slot-scope="{ item }">
              <span v-if="item.appointment && item.status == 'ongoing'">
                {{ getIncommingJob(item) || '-' | moment(datetimeFormat) }}
              </span>
              <span v-else>-</span>
            </td>
            <td slot="num_job_completed" slot-scope="{ item }">
              {{ item.num_job_completed }}/{{ item.num_of_times }}
            </td>
            <td slot="created_at" slot-scope="{ item }">
              {{ item.created_at | moment(datetimeFormat) }}
            </td>
          </CDataTable>

          <MyPagination
            :limit="pagination.limit"
            :total="pagination.total"
            :activePage="pagination.page"
            @changePage="onChangePage"
            @changePageSize="onChangePageSize"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  // ACTION_FETCH_BOOKINGS,
  ACTION_FETCH_PAGING_BOOKINGS,
  ACTION_FETCH_CATEGORIES,
} from '@/store/actions.type';
import { BookingBadge } from '@/utils/utils';
import { Common } from '@/constants';
import moment from 'moment';
import MyPagination from '../../components/MyPagination.vue';

export default {
  name: 'Bookings',
  components: { MyPagination },
  data() {
    return {
      datetimeFormat: Common.datetimeFormat,
      loading: true,
      fields: [
        { key: 'id', label: 'ID', _style: 'width: 50px' },
        { key: 'status' },
        { key: 'booking_no', label: 'BookingID' },
        { key: 'consumer_id', label: 'ConsumerID' },
        { key: 'provider_id', label: 'SP ID' },
        { key: 'address', label: 'Address' },
        { key: 'category', label: 'Category' },
        { key: 'subCategory', label: 'SubCategory' },
        // { key: 'booking_type', label: 'BookingType' },
        // { key: 'price_type', label: 'PriceType' },
        { key: 'total_price', label: 'Price' },
        { key: 'appointment', label: 'Incoming Appointment' },
        { key: 'num_job_completed', label: 'Job Completed' },
        { key: 'created_at', label: 'CreatedDate' },
      ],
      isShowFilter: false,
      search: {
        type: 'booking_id',
        value: '',
        placeholder: 'ID',
        options: [
          { value: 'booking_id', label: 'ID' },
          { value: 'booking_no', label: 'BookingID' },
          { value: 'consumer_id', label: 'ConsumerID' },
          { value: 'provider_id', label: 'ProviderID' },
        ],
      },
      filter: {
        email: '',
        status: null,
        categories: null,
        dateRangeSelected: null,
      },
      filterData: {
        status: [
          { id: 'pending', label: 'Pending' },
          { id: 'ongoing', label: 'Ongoing' },
          { id: 'completed', label: 'Completed' },
          { id: 'cancelled', label: 'Cancelled' },
          { id: 'expired', label: 'Expired' },
          { id: 'rejected', label: 'Rejected' },
          { id: 'reassigned', label: 'Reassigned' },
        ],
        categories: [],
      },
      pagination: {
        page: 1,
        limit: 25,
        total: 1,
      },
    };
  },
  mounted() {
    // this.$store.dispatch(ACTION_FETCH_BOOKINGS, {});
    this.doApplyFilter();

    this.$store.dispatch(ACTION_FETCH_CATEGORIES, {}).then(() => {
      this.filterData.categories = this.categories.map((category) => {
        return { id: category.id, label: category.name };
      });
    });
  },
  computed: {
    ...mapGetters([
      'bookings',
      'categories',
      'listCategories',
      'listSubCategories',
    ]),
    ...mapGetters({
      paging: 'pagingBookings',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.pagination.page = Number(route.query.page);
        }

        if (route.query && route.query.limit) {
          this.pagination.limit = Number(route.query.limit);
        }
      },
    },
  },
  methods: {
    selectAll() {
      this.filter.status = [];
      this.filterData.status.forEach((value) => {
        this.filter.status?.push(value.id);
      });
    },
    deselectAll() {
      this.filter.status = [];
    },
    getBadge(status) {
      return BookingBadge(status);
    },
    rowClicked(item) {
      this.$router.push({ path: `bookings/${item.id}` });
    },
    updateURL() {
      this.$router.push({
        query: {
          page: this.pagination.page,
          limit: this.pagination.limit,
        },
      });
    },
    searchChangeType() {
      this.search.placeholder = this.search.options.find(
        (x) => x.value == this.search.type
      ).label;
      this.search.value = '';
    },
    getIncommingJob(booking) {
      if (booking?.jobs?.length > 0) {
        let temp = booking?.jobs
          ?.filter((j) => j.status == 'ongoing')
          .sort((a, b) => a.id - b.id);
        return temp?.length > 0 ? temp[0].ongoing_date : null;
      }
      return null;
    },
    onChangePage(val) {
      this.pagination.page = val;
      this.updateURL();
      this.doApplyFilter();
    },
    onChangePageSize(val) {
      this.pagination.limit = val;
      this.pagination.page = 1;

      this.updateURL();
      this.doApplyFilter();
    },
    onApplyFilter() {
      this.pagination.page = 1;
      this.pagination.limit = 25;

      this.doApplyFilter();
    },
    doApplyFilter() {
      let query = {
        is_filter: false,
        status: ['ongoing', 'completed', 'cancelled', 'pending'],
        limit: this.pagination.limit,
        page: this.pagination.page,
      };
      if (this.search.value?.length > 0) {
        switch (this.search.type) {
          case 'booking_no': {
            query = { booking_no: this.search.value, is_filter: true };
            break;
          }
          case 'booking_id': {
            query = { booking_id: this.search.value, is_filter: true };
            break;
          }
          case 'consumer_id': {
            query = { consumer_id: this.search.value, is_filter: true };
            break;
          }
          case 'provider_id': {
            query = { provider_id: this.search.value, is_filter: true };
            break;
          }
        }
      }

      if (this.filter.status?.length > 0) {
        query = {
          ...query,
          status: this.filter.status,
          is_filter: true,
        };
      }

      if (this.filter.categories?.length > 0) {
        query = {
          ...query,
          category_id: this.filter.categories,
          is_filter: true,
        };
      }
      if (
        this.filter.dateRangeSelected?.length > 0 &&
        this.filter.dateRangeSelected[0] &&
        this.filter.dateRangeSelected[1]
      ) {
        query = {
          ...query,
          from: moment(this.filter.dateRangeSelected[0])
            .startOf('day')
            .format('YYYY-MM-DD'),
          to: moment(this.filter.dateRangeSelected[1])
            .endOf('day')
            .format('YYYY-MM-DD'),
          is_filter: true,
        };
      }

      this.loading = true;
      this.$store
        .dispatch(ACTION_FETCH_PAGING_BOOKINGS, query)
        .then(() => {
          this.loading = false;
          this.pagination = {
            page: Number(this.paging.page),
            limit: Number(this.paging.limit),
            total: Number(this.paging.total_rows),
          };
        })
        .catch((err) => {
          console.error(err);
        });
    },
    doResetFilter() {
      this.filter.status = null;
      this.filter.categories = null;
      this.filter.dateRangeSelected = null;
      this.pagination.limit = 25;
      this.pagination.page = 1;
      this.pagination.total = 1;

      this.updateURL();
      this.doApplyFilter();
    },
  },
};
</script>
